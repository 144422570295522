<template>
  <div
    v-editable="blok"
    class="mx-auto prose lg:prose-lg prose-img:m-0 prose-img:mb-0 prose-h2:mb-0 prose-h2:mt-1.5 prose-h2:pb-1.5 prose-a:break-all"
    :class="styles"
    v-html="richtext"
  />
</template>

<script setup lang="ts">
import type { RichTextComponentStoryblok } from '~/interfaces/storyblok/storyblok'

const props = defineProps<{
  blok: RichTextComponentStoryblok
}>()

const styles = computed(() => {
  const styles = []
  styles.push(props.blok.styles)
  if (props.blok.theme === 'dark') { styles.push('prose-invert') }

  return styles
})

const richtext = computed(() => customRenderRichText(props.blok.richText))
</script>
